import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  pallete: {
    primary: {
      dark: '#023e8a',    // Dark blue for the darkest shade
      main: '#0077b6',    // Vibrant ocean blue as the primary shade
      main600: '#0096c7', // Bright sky blue, slightly lighter
      main500: '#00b4d8', // Clear daylight blue
      main400: '#48cae4', // Soft light blue
      main300: '#90e0ef', // Very light blue, soft tone
      main200: '#ade8f4', // Pale, pastel-like blue
      main100: '#caf0f8', // Light pastel blue, very soft
      main50: '#e0f5ff',  // Very light pastel blue, almost white
  },
  secondary: {
    main: '#9c27b0', 
    secondary: '#9ff0aa'
  },
  rgba: {
      main: 'rgba(0, 119, 182, 0.8)',       // Ocean blue with opacity
      main600: 'rgba(0, 150, 199, 0.8)',    // Sky blue with opacity
      main500: 'rgba(0, 180, 216, 0.8)',    // Daylight blue with opacity
      main400: 'rgba(72, 202, 228, 0.8)',   // Soft light blue with opacity
      main300: 'rgba(144, 224, 239, 0.8)',  // Very light blue with opacity
      main200: 'rgba(173, 232, 244, 0.8)',  // Pale pastel blue with opacity
      main100: 'rgba(202, 240, 248, 0.8)',  // Light pastel blue with opacity
      main50: 'rgba(224, 245, 255, 0.8)',   // Very light pastel blue with opacity
  },
    text: {
      primary: '#ffffff',
    },
  
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Madimi One, sans-serif', 
    color: "white",
    body1: {
      fontSize: "18px",
    },
    h1: {
      fontSize: '3rem',
    '@media (min-width:900px)': {
      fontSize: '6rem',
    },
  },
    h3: {
      fontSize: '1.7rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.4rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
