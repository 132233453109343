import React from 'react';
import Container from "@mui/material/Container";
import Header from './Header';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function About() {
  return (
    <div className="about-page">
    <Header/>
    <Container>
    <Typography variant="h3" component="h1" gutterBottom>
                Spinning Your Way to Fun: A Guide to Free Social Pokies
            </Typography>
            <Typography paragraph>
                In the world of online gaming, social pokies have emerged as a popular form of entertainment, offering a fun and engaging way to pass the time. These games simulate the excitement of traditional slot machines without the need for real money transactions. This article explores the world of free social pokies, discussing their appeal, how they function, and where to find the best games.
            </Typography>

            <Box mt={4}>
                <Typography variant="h4" component="h2">
                    The Appeal of Free Social Pokies
                </Typography>
                <Typography paragraph>
                    Free social pokies offer a unique blend of entertainment and social interaction, making them an attractive option for players of all ages and backgrounds.
                </Typography>
                <ul>
                    <li><Typography>No financial risk: Completely devoid of financial transactions, ensuring players can enjoy the fun without monetary involvement.</Typography></li>
                    <li><Typography>Accessible and convenient: Enjoy games anytime and anywhere, perfect for unwinding or passing the time.</Typography></li>
                    <li><Typography>Social interaction: Features that allow players to connect globally, enhancing the gaming experience.</Typography></li>
                </ul>
            </Box>

            <Box mt={4}>
                <Typography variant="h4" component="h2">
                    How Free Social Pokies Function
                </Typography>
                <Typography paragraph>
                    Explore various free social pokies, each with unique themes and features. Select and play using virtual credits for a fun experience.
                </Typography>
                <ol>
                    <li><Typography>Choose your game: Pick from a variety of games based on personal interests.</Typography></li>
                    <li><Typography>Spin the reels: Activate the game to see outcomes, with no real money involved.</Typography></li>
                </ol>
            </Box>

            <Box mt={4}>
                <Typography variant="h4" component="h2">
                    Where to Find the Best Free Social Pokies
                </Typography>
                <Typography paragraph>
                    Opt for platforms known for quality and explore demos to find the best fit for your gaming preferences.
                </Typography>
                <ul>
                    <li><Typography>Look for reputable platforms: Choose for quality games and secure experiences.</Typography></li>
                    <li><Typography>Read reviews: Gain insights and identify any potential issues through feedback.</Typography></li>
                    <li><Typography>Explore trial versions: Try out games before engaging more extensively.</Typography></li>
                </ul>
            </Box>

    </Container>
      </div>
  )
}
